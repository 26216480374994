import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/docs.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "700px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/020f67d7203f07eb9b585c770f7f4003/29d31/item.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "55.99999999999999%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAQFAQP/xAAUAQEAAAAAAAAAAAAAAAAAAAAC/9oADAMBAAIQAxAAAAG5irSHQmiP/8QAGxAAAgEFAAAAAAAAAAAAAAAAAAERAhAhMTL/2gAIAQEAAQUCyKVZbr5ln//EABcRAAMBAAAAAAAAAAAAAAAAAAABERP/2gAIAQMBAT8BTpof/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAERE//aAAgBAgEBPwFqGZ//xAAYEAACAwAAAAAAAAAAAAAAAAAAASAhMf/aAAgBAQAGPwKoM0//xAAZEAADAQEBAAAAAAAAAAAAAAAAAREhQRD/2gAIAQEAAT8hba0qIzk8wpiRLo//2gAMAwEAAgADAAAAELw//8QAFhEBAQEAAAAAAAAAAAAAAAAAEQBR/9oACAEDAQE/EBMMv//EABYRAQEBAAAAAAAAAAAAAAAAABEAUf/aAAgBAgEBPxBBstv/xAAcEAEBAAMAAwEAAAAAAAAAAAABEQAhQWFxgZH/2gAIAQEAAT8QAbbhXU85yFrdtnPuEe40QoCT9zaaMXA0Ie8//9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "item",
            "title": "item",
            "src": "/static/020f67d7203f07eb9b585c770f7f4003/29d31/item.jpg",
            "srcSet": ["/static/020f67d7203f07eb9b585c770f7f4003/f93b5/item.jpg 300w", "/static/020f67d7203f07eb9b585c770f7f4003/b4294/item.jpg 600w", "/static/020f67d7203f07eb9b585c770f7f4003/29d31/item.jpg 700w"],
            "sizes": "(max-width: 700px) 100vw, 700px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "how-materia-uses-the-item-standard",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#how-materia-uses-the-item-standard",
        "aria-label": "how materia uses the item standard permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How Materia uses the ITEM Standard`}</h2>
    <p>{`Materia uses the ITEM Standard as the foundation of its core. Pairs within Materia will be composed of WUSD, a super stablecoin which is a native ITEM and generic ITEMs.`}</p>
    <p>{`While adding liquidity or swapping, if ERC20 tokens are used, they will be automatically wrapped inside ITEMs enabling future developments and the use of ERC1155 features inside Materia.`}</p>
    <p>{`Taking advantage of the ITEM Standard, Materia will able to grant features such as Batch/Lego Swap, remove the approving transactions the transfer of tokens and awsome new functionalites that will be developed over time.`}</p>
    <h2 {...{
      "id": "how-item-standard-works",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#how-item-standard-works",
        "aria-label": "how item standard works permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How ITEM Standard works`}</h2>
    <p>{`ITEM is a new object standard on top of Ethereum. It synergizes the properties of the three most common interface (ERC20, ERC721, ERC1155) and is thus interoperable with all existing Ethereum applications. By taking advantage of the sophisticated engineering behind ERC1155s, ITEMs can be used as an ERC20, ERC721 or as an ERC1155 as required.`}</p>
    <p>{`ITEMs don’t need centralized storage to save objectId info. NFT data is saved in a specific ERC20 token that can also work as a standalone one, and allow for the transfer of parts of an atomic object. The ERC20 implementation has a unique Token Id, and its supply is the entire supply of that Token Id.`}</p>
    <p>{`EthItem is designed to be extendable. Every new Collection can be hosted by a specific wallet, or better yet by a Smart Contract that has the right to mint new Items. This allows all developers to build their own minting rules and create an entire decentralized application based on it in a totally reusable general-purpose way. This is because, for the first time, the application’s logic (e.g. for a videogame) is fully decoupled from the NFT implementation itself.`}</p>
    <h2 {...{
      "id": "wrapped-items-vs-native-items",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#wrapped-items-vs-native-items",
        "aria-label": "wrapped items vs native items permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Wrapped ITEMs vs Native ITEMs`}</h2>
    <p>{`Native ITEMs are collections objects that, via their Extensions, can perform complex behaviors specified optionally in the Extension logic. These extra capabilities are up to the developers writing the Extension logic.`}</p>
    <p>{`Wrapped ITEMs, on the other hand, retain all the capabilities of the default ITEM standard, but lose any extra ones while wrapped. For example, once wrapped, A DAO or DFO governance token cannot interact with the DAO or DFO (until unwrapped), but can still be traded like any ITEM using a gas-efficient method like BatchTransfer.`}</p>
    <p>{`To be clear: Wrapped items CANNOT have Extensions.`}</p>
    <h2 {...{
      "id": "unique-vs-fungible-items",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#unique-vs-fungible-items",
        "aria-label": "unique vs fungible items permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Unique vs fungible ITEMs`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1080px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/89c2ce2094d5dfd27b47afd3e75bb8d8/302a4/item-view.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "51.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAACbElEQVQoz2NgAILTL37mn3v62YsBCtbc/8u9/f73abMPXhcB8VdeeAEWn/lmFkwJw9P/DAzzrv5kBLEXXP9uNPPKN2u45Kobn/2WXnqnDuPPufSZc+udL6nLj9/iAfGn7L/N8P8/RO7kSyOlc++1REHsrQ8mgg2ceuaN1KTTbxXBCuafvsgINYdxxc5VYLZ07AQwrd58iill40tmBiRw8LoaO8zwhvOpDCuPgjhsjNpexcwV279CzPr//z/jn7P/GZ17jvOEzL8mEtC1lf3/t7PM2UvPc/vNOM+jaO7OaFMyjz1s0XUZn1mPJAPnPZD2m7SfmwEbuP56MgvIdQzeDMxdu/dyhC56xKbecZYdIjuD6f//fgYG+WAGs7QuZt9JxziCph/nDpx+gssssYcJpGJy1X8mLYbPbOUrnkT5TXkhzHDsBwPfh78z6j/8WVC95TcD2KCQzV81b7z9u3n1zd9JUB/AHRA44wyYjpl9l4vhfw3jHIb/PPMmvO7fdevH/6j+Nw4MB38x8H75t3Tql3/LJs39yMABVrztq/6Tz/8e7Lz/pxoWJDYTzjMEzbvK4NW9h9G9fRtzWP8FTpCcF98H8dmLnh9uWPT6hp7Te12GO981eI684Vc/9pZT9cpnK7Ai66XvhTXnvFczXPBRruHYb3BAu635weDZvRsjyKYF/+bK8v5k0WD61fCC+n9uBtPO/0y+U/9z+QGxQ/d/cLisOnOHdfXx85zLj1/inHvlA1jj3GM3wLR37z4G9/atDOF7ToL5cf+XM6z/f569/f8ZTvH/OxgxbCyZ8h9D7Mabtwy4wGoGhPr/QAgAkN0PloTIgg8AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "item view",
            "title": "item view",
            "src": "/static/89c2ce2094d5dfd27b47afd3e75bb8d8/302a4/item-view.png",
            "srcSet": ["/static/89c2ce2094d5dfd27b47afd3e75bb8d8/5a46d/item-view.png 300w", "/static/89c2ce2094d5dfd27b47afd3e75bb8d8/0a47e/item-view.png 600w", "/static/89c2ce2094d5dfd27b47afd3e75bb8d8/302a4/item-view.png 1080w"],
            "sizes": "(max-width: 1080px) 100vw, 1080px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`For the EthITEM standard, there are two different ways to handle tokens. The first is for tokens with a limited supply of one, the second for tokens with a limited supply of more than one. Let’s say you want to wrap an NFT with a supply of 1. How can we guarantee that the original NFT will still be accessible and function as a unique token, but not be inflated?`}</p>
    <p>{`The solution is in the way a unique token is wrapped as an ITEM. Once you wrap it, you’ll be able to redeem its underlying assets entirely. ETHITEM stores information on how many of the generated ITEMs have been burnt to redeem the underlying assets. If someone rewraps the unique token, he will obtain the number of tokens burnt in the first place.`}</p>
    <h2 {...{
      "id": "read-more",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#read-more",
        "aria-label": "read more permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Read more`}</h2>
    <p>{`To read more about EthItem you can check the official `}<a parentName="p" {...{
        "href": "https://ethitem.com/"
      }}>{`web site`}</a>{` and the `}<a parentName="p" {...{
        "href": "https://ethitem.com/doc.html"
      }}>{`nerd section`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      